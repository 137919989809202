import type { ListPage, MenuItem } from '@madxnl/mrrabbit'

export const config: MenuItem[] = [
  {
    name: 'Users',
    listPage: {
      identifier: 'id',
      type: 'User',
      model: 'User',
      allowNew: true,
      fields: {
        id: true,
        createdAt: { formatting: 'date' },
        callId: true,
        email: true,
        phoneNumber: true,
      },
      editPage: {
        fields: {
          id: { type: 'text', readOnly: true },
          devices: {
            identifier: 'id',
            type: 'Device',
            model: 'Device',
            allowNew: true,
            fields: {
              id: true,
              createdAt: { formatting: 'date' },
              serial: true,
              secret: true,
            },
            editPage: {
              fields: {
                id: { type: 'text', readOnly: true },
                createdAt: { type: 'text', readOnly: true },
                serial: { type: 'text' },
                secret: { type: 'text' },
              },
            },
          } as ListPage<any>,
          shortcuts: {
            identifier: 'name',
            type: 'Shortcut',
            model: 'Shortcut',
            allowNew: true,
            fields: {
              name: true,
              action: true,
              icon: true,
              image: true,
              value: true,
            },
            editPage: {
              fields: {
                id: { type: 'text', readOnly: true },
                createdAt: { type: 'text', readOnly: true },
                name: { type: 'text' },
                action: { type: 'enum' },
                icon: { type: 'text' },
                image: { type: 'text' },
                value: { type: 'text' },
              },
            },
          } as ListPage<any>,
        },
      },
    } as ListPage<any>,
  },
  {
    name: 'Calls',
    listPage: {
      identifier: 'id',
      type: 'Room',
      model: 'Room',
      allowNew: true,
      fields: {
        id: true,
        createdAt: { formatting: 'date' },
        state: true,
      },
      editPage: {
        fields: {
          id: { type: 'text', readOnly: true },
          events: {
            identifier: 'id',
            type: 'RoomEvent',
            model: 'RoomEvent',
            allowNew: true,
            fields: {
              id: true,
              createdAt: { formatting: 'date' },
              type: true,
            },
            editPage: {
              fields: {
                id: { type: 'text', readOnly: true },
                createdAt: { type: 'text', readOnly: true },
                type: { type: 'enum' },
                user: {
                  identifier: 'callId',
                  type: 'User',
                  model: 'User',
                  fields: {
                    callId: true,
                  },
                } as ListPage<any>,
              },
            },
          } as ListPage<any>,
          invites: {
            identifier: 'id',
            type: 'Invite',
            model: 'Invite',
            allowNew: true,
            fields: {
              id: true,
              createdAt: { formatting: 'date' },
              state: true,
            },
            editPage: {
              fields: {
                id: { type: 'text', readOnly: true },
                createdAt: { type: 'text', readOnly: true },
                state: { type: 'enum' },
                fromUser: {
                  identifier: 'callId',
                  type: 'User',
                  model: 'User',
                  fields: {
                    callId: true,
                  },
                } as ListPage<any>,
                toUser: {
                  identifier: 'callId',
                  type: 'User',
                  model: 'User',
                  fields: {
                    callId: true,
                  },
                } as ListPage<any>,
              },
            },
          } as ListPage<any>,
        },
      },
    } as ListPage<any>,
  },
]
